.edit-tip-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.edit-tip-modal {
    position: fixed !important;
    margin: auto;
    /* min-height: 300px; */
    height: auto;
    z-index: 9020!important;
}

.edit-tip-modal-no-tip {
    position: fixed !important;
    margin: auto;
    /* min-height: 300px; */
    height: auto;
    z-index: 9020!important;
}

/* .push-heading {
    margin-top: 7.5vh!important;
} */

.edit-tip-container .booking-submit-btn {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    height: 50px;
    border: 1px solid #01003b;
    padding: 0 5px;
    box-shadow: none !important;
}

.edit-tip-container .booking-submit-btn .booking-submit-btn-text {
    font-family: Lato-Bold,sans-serif;
}

.edit-tip-container .booking-submit-btn span {
    font-size: 14px;
    height: fit-content;
    color: #01003b;
}

.edit-tip-modal input {
    width: auto;
}

.edit-tip-modal .edit-tip-modal-feedback-input {
    width: -webkit-fill-available;
    height: 42px;
    border-radius: 4px;
    border: solid 2px #d5d5d5;
    background-color: #ffffff;
    margin: auto;
}

.edit-tip-container .booking-submit-btn.active {
    background: #020082;
}

.edit-tip-container .booking-submit-btn.active span {
    color: #ffffff;
}

.edit-tip-modal .form-group {
    text-align: left;
}

.edit-tip-modal .invalid-feedback {
    text-align: left;
    margin: 0;
}

.tip-amount * {
    font-family: Lato-Regular, sans-serif;
}

.tip-amount h3 {
    font-size: 16px;
}

.tip-amount span {
    font-weight: bold;
}

.total-tip-amount {
    margin-bottom: 10px;
}

@media all and (max-width: 355px) {
    .edit-tip-container .booking-submit-btn {
        width: 130px;
    }
}

@media all and (max-width: 600px) {
    /* .edit-tip-modal-no-tip, .edit-tip-modal{
        min-width: 100vw !important;
        width: 100% !important;
        height: 100% !important;
        border-radius: 0 !important;
    } */

    .edit-tip-container .booking-submit-btn{
        width: 135px;
    }

    .order-problem-btns{
        flex-direction: column !important;
    }

    .prob-with-food{
        margin-bottom: 10px;
    }

    .prob-with-service, .prob-with-food{
        width: 100% !important;
    }
}