.free-modifier-button {
    border-radius: 33px;
    border: solid 2px #01003b;
    background-color: #ffffff;
    font-family: Lato-Regular, Cormorant, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.14px;
    text-align: center;
    color: #01003b;
}

.free-modifier-button.active {
    background-color: #01003b;
    color: #f5f5f5;
}

.free-modifier-buttons {
    display: flex;
    /*justify-content:  center;*/
    overflow-x: scroll;
    scrollbar-color: #212529 white;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/* .free-modifier-buttons::-webkit-scrollbar {
    height: 5px;
    background: white;
}

.free-modifier-buttons::-webkit-scrollbar-thumb {
    background: #212529;
    border-radius: 5px;
}

.free-modifier-buttons::-webkit-scrollbar-corner {
    background: #212529;
} */

.free-modifier-buttons > button:first-child {
    margin-left: auto;
}

.free-modifier-buttons > button:last-child {
    margin-right: auto;
}

.free-modifier-buttons button {
    margin: 5px;
}
.free-modifier-buttons button:active, .free-modifier-buttons button:focus {
    outline: none;
    box-shadow: none;
}

h4.free-modifier-heading {
    margin: 0.5rem 0;
    /* font-family: Cormorant-Regular, serif; */
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #333333;
    text-transform: none;
}

.requred-modifiers{
    text-align: center;
    color: red;
}

.free-modifier-buttons-block {
    display: block;
}