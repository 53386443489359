.floating-island-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0.5rem;
    z-index: 9000;
    object-fit: contain;
    width: 100%;
    max-width: 28rem;
    left: 50%;
    transform: translate(-50%,0);
    border-radius: 1rem;
    background-color: transparent;
    animation: slideIn 0.4s ease-in-out;
}

.floating-island-wrapper {
    background-color: #020083;
    width: 95%;
    display: flex;
    flex-wrap: nowrap;
    min-height: 4.125rem;
    justify-content: flex-end;
    padding: 0.5rem;
    border-radius: 999rem;
    gap:0.5rem;
}

.floating-island-wrapper-2 {
    background-color: #020083;
    width: 95%;
    display: flex;
    flex-wrap: nowrap;
    height:5rem;
    justify-content: space-between;
    padding: 0.5rem;
    border-radius: 1.5rem;
    gap:0.5rem;
}

.floating-island-more-options-container {
    display: flex;
    gap:0.5rem;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    height: 2.5rem;
    width: 100%;
    max-width: 28rem;
    border-top-right-radius: 2rem;
    border-top-left-radius: 2rem;
    margin: 0;
    font-family: Lato;
    font-size: 1rem;
    padding-bottom: 0.1rem;
    color: #020083;
    cursor: pointer;
}

.floating-island-button-wrapper {
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;
}
.floating-island-button-wrapper-2 {
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;
    /* opacity: 0;
    transform: translateY(100px); */
    /* animation: slideInTop 0.4s ease-in-out forwards; use the slideIn animation */
}
.floating-island-button-wrapper-full {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.floating-island-button {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    /* min-width: 10rem; */
    height: 3rem;
    line-height: 1;
    border-radius: 2rem;
    background-color: white;
    font-family: Lato;
    font-size: 1rem;
    text-align: center;
    color: #020083;
    gap: 0.5rem;
    border:1px solid #020083;
    cursor: pointer;
}
.floating-island-button-2 {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 3rem;
    line-height: 1;
    border-radius: 2rem;
    background-color:#020083;
    font-family: Lato;
    font-size: 1rem;
    text-align: center;
    color: #fff;
    gap: 0.5rem;
    border:1px solid #fff;
    cursor: pointer;
}

.floating-island-button:disabled,
.floating-island-button-2:disabled,
.floating-island-button-top:disabled {
    background-color: gray;
    border-color: gray;
    color: white;
}

.floating-island-button:hover,
.floating-island-button-top:hover {
    background-color: rgb(238, 238, 238);
}

.floating-island-button-top {
    width: 80%;
    min-width: 10rem;
    height: 2.6rem;
    border-radius: 2rem;
    background-color: white;
    font-family: Lato;
    font-size: 1rem;
    text-align: center;
    color: #020083;
    gap: 0.5rem;
    border:1px solid #020083;
    cursor: pointer;
}

.floating-island-more-options-wrapper {
    display: flex;
    flex-direction: column;
    width: 70%;
    gap: 0.5rem;
    /* margin:0.25rem 0; */
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    /* opacity: 0;
    transform: translateY(20px); */
}

.fade-in .floating-island-more-options-wrapper {
    opacity: 1;
    transform: translateY(0);
}

.fade-out .floating-island-more-options-wrapper {
    opacity: 0;
    transform: translateY(20px);
}

.floating-island-empty-slot {
    display: none;
    opacity: 0;
    pointer-events: none;
    visibility: none;
    z-index: -1;
}

.floating-island-button-wrapper-2 {
    display: flex;
    flex-basis: 47.5%;
    justify-content: center;
    align-items: center;
}

.floating-island-message {
    font-family: Lato-Italic;
    color: #fff;
    font-size: 1rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    line-height: 1.2;
}

.floating-island-orders-ball {
    width: 2rem!important;
    height: 2rem!important;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Lato;
    font-weight: bold;
    font-size: 14px;
    background-color: #020083;
    color: #fff;
    /* padding: 0 0 0.25rem 0; */
}

.floating-island-orders-ball-inverted {
    width: 2rem!important;
    height: 2rem!important;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Lato;
    font-weight: bold;
    font-size: 14px;
    background-color: #fff;
    color: #020083;
    /* padding: 0 0 0.25rem 0; */
}

.floating-island-button-2:disabled, .floating-island-button-top:disabled, .floating-island-button:disabled {
    background-color: #B0B4BA;
    border-color: #B0B4BA;
    color: #020083;
}

/* .floating-island-container.slideIn {
    animation: slideIn 0.4s ease-in-out;
}


.floating-island-container.slideOut {
    animation: slideOut 0.4s ease-in-out;
}

.floating-island-button-wrapper-2.slideOutTop {
    animation: slideOutTop 0.4 ease-in-out;
}

.floating-island-button-wrapper-full.shrinkSlot1 {
    animation: shrinkSlot1 4s ease-in-out;
}

.floating-island-button-wrapper.expandSlot1 {
    animation: expandSlot1 4s ease-in-out;
}

.floating-island-slot-2.expand {
    animation: expandSlot2 4s ease-in-out;
}

.floating-island-slot-1.shrink {
    animation: shrinkSlot2 4s ease-in-out;
}

@media only screen and (max-width: 410px) {
    .floating-island-more-options-container {
        width: 46%;
    }
} */

/* @keyframes expandSlot1 {
    from {
        flex-basis: 47.5%;
        opacity: 1;
    }
    to {
        flex-basis: 100%;
        opacity: 1;
    }
}

@keyframes shrinkSlot2 {
    from {
        flex-basis: 47.5%;
        opacity: 1;
    }
    to {
        flex-basis: 0;
        opacity: 0;
    }
}

@keyframes shrinkSlot1 {
    from {
        flex-basis: 100%;
        opacity: 1;
    }
    to {
        flex-basis: 47.5%;
        opacity: 0;
    }
}

@keyframes expandSlot2 {
    from {
        flex-basis: 0;
        opacity: 0;
    }
    to {
        flex-basis: 47.5%;
        opacity: 1;
    }
}
 */

@keyframes slideInTop{
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideOutTop {
    from {
        transform: translateY(0);
        /* opacity: 1; */
    }
    to {
        transform: translateY(50px);
        /* opacity: 0; */
    }
}

@keyframes slideIn {
    from {
        transform: translateY(100%) translateX(-50%);
    }
    to {
        transform: translateY(0) translateX(-50%);
    }
}

@keyframes slideOut {
    from {
        transform: translateY(0) translateX(-50%);
        opacity: 1!important;
    }
    to {
        transform: translateY(100%) translateX(-50%);
        opacity: 0!important;
    }
}

@keyframes show {
    from {
        opacity: 0!important;
    }
    to {
        opacity: 1!important;
    }
}
@media only screen and (max-width: 700px) {
    .floating-island-container {
        background: linear-gradient(rgba(238, 238, 238, 0), rgba(255, 255, 255, 0.94) 46%) !important;
    }
    .floating-island-more-options-container {
        background: linear-gradient(rgba(238, 238, 238, 0), rgba(255, 255, 255, 0.94) 46%) !important;
    }
    .floating-island-message {
        font-size: 1rem!important;
    }
}