.custom-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border:1px solid;
}

.icon-wrapper {
    display: flex;
    flex-flow: column wrap;
}
.icon-dash {
    width: 14px;
    height: 2px;
    background:#01003b;
    border-radius: 2px;
    margin: 0.1rem 0;
}

.input-label {
    margin-left: calc(50% - 154px);
    height: 17px;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 17px;
    letter-spacing: normal;
    color: #333333;
}

.input-label-profile {
    height: 17px;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 17px;
    letter-spacing: normal;
    color: #333333;
}

.input-info {
    font-family: Lato-Regular, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 17px;
    letter-spacing: normal;
    color: #333333;
    width: 308px;
    margin: 4px auto auto auto;
    text-align: center;
}

/* .invalid-feedback, .input-label {
    margin: auto;
} */

.input-style {
    width: 308px;
    height: 48px;
    border-radius: 12px;
    border: solid 1px #d5d5d5;
    background-color: #ffffff;
    margin: auto;
}
.form-group {
    margin-bottom: 1rem;
}

.myCustomList {
    overflow-y: scroll!important;
    height: 50vh!important;
}

.myCustomList::-webkit-scrollbar {
    width: 8px!important;
    background-color: lightgray;
    display: block;
  }

  .myCustomList::-webkit-scrollbar-thumb {
    border-radius: 10px!important;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #020083;
  }

@media only screen and (max-width: 420px) {
    .input-style {
        width: 100%;
    }

    .input-label{
        margin-left: 0px;
    }
}
